var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"containerx mx-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-3"},[_c('div',{staticClass:" d-flex flex-column flex-grow-1"},[_c('h2',{staticClass:"font font-weight-bold font-size-lg blue-grey--text"},[_vm._v(" Exports & Downloads ")]),_c('p',{staticClass:" font font-weight-medium"},[_vm._v(" Get access to all reports, view, customize and export them ")])])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('v-autocomplete',{staticClass:" font ml-1 font-weight-medium font-size-sm",attrs:{"items":_vm.reports,"item-text":"name","item-value":"id","label":"Select the type of reports to download","hide-details":"","dense":"","flat":"","solo":"","background-color":"#e8e8e8"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:" font font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.name))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"box-shadow-light",attrs:{"label":"","color":"primary","small":""}},[_c('span',{staticClass:"font font-weight-medium",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.name))])])]}}]),model:{value:(_vm.report),callback:function ($$v) {_vm.report=$$v},expression:"report"}}),(_vm.report === 'LUBES_GENERAL_MONTHLY_REPORTS')?_c('div',{staticClass:" mx-1 d-flex flex-column"},[_c('v-autocomplete',{staticClass:" font  font-weight-medium font-size-sm",attrs:{"items":[].concat( _vm.monthlyDates ),"item-text":"label","item-value":"value","label":"Choose Month/Duration","dense":"","flat":"","solo":"","background-color":"#e8e8e8"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:" text-uppercase font font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.label))])]}}],null,false,3946647369),model:{value:(_vm.lubesMonthlyDate),callback:function ($$v) {_vm.lubesMonthlyDate=$$v},expression:"lubesMonthlyDate"}})],1):_c('div',{staticClass:"d-flex flex-column"},[(_vm.report !== 'CUSTOMERS_LIST')?_c('v-menu',{ref:"menu",staticClass:"font font-weight-medium",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto","nudge-left":80,"nudge-bottom":6},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"  font mx-1 text-uppercase font-weight-medium",staticStyle:{"padding":"19px !important"},attrs:{"label":"","close":_vm.dates.length === 2,"color":_vm.dates.length > 0 ? 'primary' : 'white'},on:{"click:close":function($event){_vm.dates = []}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},'v-chip',attrs,false),on),[_c('i',{staticClass:"material-icons-outlined font-size-md mr-2"},[_vm._v("event")]),_vm._v(" "+_vm._s(_vm.dates.length > 0 ? _vm.dateRangeText : "Choose date range")+" ")])]}}],null,false,4244458018),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"font font-weight-medium font-size-sm",attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{staticClass:" font font-weight-medium",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){(_vm.menu = false), (_vm.dates = [])}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"font font-weight-medium",attrs:{"text":"","color":"primary"},on:{"click":_vm.filteredByDates}},[_vm._v(" OK ")])],1)],1):_c('v-autocomplete',{staticClass:" font ml-2 font-weight-medium font-size-sm",attrs:{"items":_vm.customerTypeList,"label":"Choose Option","dense":"","item-value":"value","item-text":"text","flat":"","solo":"","background-color":"#e8e8e8"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:" text-uppercase font font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.customerType),callback:function ($$v) {_vm.customerType=$$v},expression:"customerType"}})],1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[(
            (_vm.report === 'CUSTOMERS_LIST' && _vm.records.length > 0) ||
              (_vm.records.length > 0 && _vm.dates.length > 1 && _vm.report !== null)
          )?_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[(_vm.report !== 'LUBE_SALES')?_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end mb-2"},[_c('v-btn',{staticClass:"mr-1",attrs:{"outlined":"","small":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile('xlsx')}}},[_c('span',{staticClass:" font font-weight-medium font-size-sm text-uppercase"},[_vm._v("Export As Excel")])]),_c('v-btn',{attrs:{"outlined":"","small":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile('csv')}}},[_c('span',{staticClass:" font font-weight-medium font-size-sm text-uppercase"},[_vm._v("Export As CSV")])])],1):_vm._e(),(_vm.report !== 'LUBE_SALES')?_c('v-data-table',{staticClass:" font box-shadow-light font-weight-medium font-size-sm",attrs:{"loading":_vm.listLoading,"items":_vm.records,"headers":_vm.titles,"footer-props":{
              itemsPerPageOptions: [15, 30]
            }},scopedSlots:_vm._u([{key:"item.productDeliveries",fn:function(ref){
            var item = ref.item;
return [(
                  item.productDeliveries && item.productDeliveries.length > 0
                )?[_c('div',{staticClass:"d-flex flex-row flex-wrap"},_vm._l((item.productDeliveries),function(delivery,index){return _c('v-chip',{key:("del-" + (item.chequeNmber) + "-" + index),staticClass:"box-shadow-light mb-1",attrs:{"label":"","x-small":""}},[_c('span',{staticClass:" font font-weight-medium font-size-sm"},[_vm._v(_vm._s(delivery.branchName)+"- Qty: "+_vm._s(delivery.quantity))])])}),1)]:_vm._e()]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.amount)))])]}},{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.date)))])]}},{key:"item.loadingDate",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.loadingDate)))])]}},{key:"item.totalAmountBefore",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.totalAmountBefore)))])]}},{key:"item.currentTotalAmount",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.currentTotalAmount)))])]}},{key:"item.creditPurchasesAmount",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.creditPurchasesAmount)))])]}}],null,false,1456081349)}):[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap"},_vm._l((_vm.records),function(record,index){return _c('div',{key:index,staticClass:"d-flex flex-grow-1 flex-column"},[_c('div',{staticClass:" d-flex flex-row mt-1"},[_c('v-chip',{staticClass:"box-shadow-light",attrs:{"small":"","label":"","color":"primary"}},[_c('span',{staticClass:" font font-weight-medium font-size-md"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(record[0]))+" ")])])],1),_c('v-data-table',{staticClass:" font font-weight-medium  ma-1 font-size-sm box-shadow-light",attrs:{"items":record[1],"headers":_vm.titles}})],1)}),0)]],2):_c('EmptyPage',{attrs:{"title":_vm.branchId !== null &&
            _vm.dates.length > 1 &&
            _vm.report !== null &&
            _vm.records.length === 0
              ? ("No records found within this date range - " + (_vm.dates.join('~')))
              : 'Exports your reports for external use',"subTitle":'Have a view of your chosen report, export them to excel, csv etc.',"imageWidth":"200px","image":require('@/assets/search.png')},scopedSlots:_vm._u([(_vm.listLoading)?{key:"action",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":""}})]},proxy:true}:null],null,true)})],1)],1)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }